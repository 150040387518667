import { createRouter, createWebHashHistory } from "vue-router";
import { useAuthStore } from "@/pinia/auth.js";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";
// import { bus } from '@/main.js'

const routes = [
  {
    path: "/",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    children: [
      {
        path: "/",
        name: "dashboard",
        component: () => import("@/page/Dashboard.vue"),
        meta: { pageTitle: "Dashboard", requiresAuth: true },
      },
    ],
  },
  {
    path: "/admins",
    redirect: "/admins",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    children: [
      {
        path: "/admins",
        name: "admins",
        component: () => import("@/page/admin/index.vue"),
        meta: {
          pageTitle: "Admin",
          requiresAuth: true,
          breadcrumbs: ["Administrations", "Admins"],
        },
      },
      {
        path: "/modules",
        name: "modules",
        component: () => import("@/page/module/index.vue"),
        meta: {
          pageTitle: "Module",
          requiresAuth: true,
          breadcrumbs: ["Administrations", "Modules"],
        },
      },
      {
        path: "/roles",
        name: "roles",
        component: () => import("@/page/role/index.vue"),
        meta: {
          pageTitle: "Role",
          requiresAuth: true,
          breadcrumbs: ["Administrations", "Roles"],
        },
      },
      {
        path: "/export-files",
        name: "export_files",
        component: () => import("@/page/file/index.vue"),
        meta: {
          pageTitle: "Export file",
          requiresAuth: true,
          breadcrumbs: ["File", "Export"],
        },
      },
      {
        path: "/activity-log",
        name: "activity-log",
        component: () => import("@/page/activity_log/index.vue"),
        meta: {
          pageTitle: "Configuration",
          requiresAuth: true,
          breadcrumbs: ["Administrations", "Activity Log"],
        },
      },
      {
        path: "/campaign",
        name: "campaign",
        component: () => import("@/page/campaign/index.vue"),
        meta: {
          pageTitle: "Campaign",
          requiresAuth: true,
          breadcrumbs: ["Campaign"],
        },
      },
      {
        path: "/campaign-um-coding-entry",
        name: "campaign-um-coding-entry",
        component: () => import("@/page/campaign_um_code/index.vue"),
        meta: {
          pageTitle: "Campaign UM Coding Entry",
          requiresAuth: true,
          breadcrumbs: ["Campaign Entry"],
        },
      },
      {
        path: "/campaign-entry",
        name: "campaign-entry",
        component: () => import("@/page/campaign_entry/index.vue"),
        meta: {
          pageTitle: "Campaign Entry",
          requiresAuth: true,
          breadcrumbs: ["Campaign Entry"],
        },
      },
      {
        path: "/mission",
        name: "mission",
        component: () => import("@/page/mission/index.vue"),
        meta: {
          pageTitle: "Mission",
          requiresAuth: true,
          breadcrumbs: ["Mission"],
        },
      },
      {
        path: "/mission-entry",
        name: "mission-entry",
        component: () => import("@/page/mission_entry/index.vue"),
        meta: {
          pageTitle: "Mission Entry",
          requiresAuth: true,
          breadcrumbs: ["Mission Entry"],
        },
      },
      {
        path: "/user",
        name: "user",
        component: () => import("@/page/user/index.vue"),
        meta: {
          pageTitle: "User",
          requiresAuth: true,
          breadcrumbs: ["User"],
        },
      },
      {
        path: "/banner",
        name: "banner",
        component: () => import("@/page/banner/index.vue"),
        meta: {
          pageTitle: "Banner",
          requiresAuth: true,
          breadcrumbs: ["Banner"],
        },
      },
      {
        path: "/services",
        name: "services",
        component: () => import("@/page/services/index.vue"),
        meta: {
          pageTitle: "Services",
          requiresAuth: true,
          breadcrumbs: ["Administrations", "Services"],
        },
      },
      {
        path: "/promotion-emails",
        name: "promotion-email",
        component: () => import("@/page/promotion_email/index.vue"),
        meta: {
          pageTitle: "Promotion Emails",
          requiresAuth: true,
          breadcrumbs: ["Promotional Email"],
        },
      },
      {
        path: "/featured-slider",
        name: "featured-slider",
        component: () => import("@/page/featured_slider/index.vue"),
        meta: {
          pageTitle: "Featured slider",
          requiresAuth: true,
          breadcrumbs: ["Featured Slider"],
        },
      },
      {
        path: "/brand",
        name: "brand",
        component: () => import("@/page/brand/index.vue"),
        meta: {
          pageTitle: "Brand",
          requiresAuth: true,
          breadcrumbs: ["Brand"],
        },
      },
      {
        path: "/campaign-field",
        name: "campaign-field",
        component: () => import("@/page/campaign_field/index.vue"),
        meta: {
          pageTitle: "Campaign Field",
          requiresAuth: true,
          breadcrumbs: ["Campaign Field"],
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/page/SignIn.vue"),
        meta: { pageTitle: "Sign In" },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  },
});

//check auth
router.beforeEach(async (to, from, next) => {
  // current page view title
  document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`;
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  const authStore = useAuthStore();
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (authStore.isAuth) {
      //check article edit page
      if (to.name == "edit-article" && from.path !== "/articles") {
        next("/articles");
      }
      if (to.name == "edit-video" && from.path !== "/videos") {
        next("/videos");
      }

      next();
      return;
    } else {
      next("/sign-in");
    }
  } else if (to.matched.some((record) => record.meta.onlyGuest)) {
    next();
  } else {
    next();
  }
});

export default router;
